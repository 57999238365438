import { groq } from '@nuxtjs/sanity';
/**
 * In this query, we retrieve a page and its translations
 */

export default function getPageQuery(slug, locale) {
  // TODO: make this function a plugin for Vue and use defaultLang
  // Query for all pages
  let defaultQuery = `(_type=="page" || _type=="recipe") &&
  live==true &&
  url.fullPath=="${slug}" &&
  __i18n_lang=="${locale}" &&
  !(_id in path("drafts.**"))`;

  // Query for the homepage. We need to do that because homepage as no slug by default
  if (slug === '') {
    defaultQuery = `_type=="page" &&
    live==true &&
    isHome==true &&
    __i18n_lang=="${locale}" &&
    !(_id in path("drafts.**"))`;
  }

  return groq`*[${defaultQuery}][0] {
  ...,
  content{
    ...,
    sections[]{
      (_type == "detailHero") => {
        "background": picture.refImage->{"image": upload.asset->{url}},
        ...
      },
      ...,
    }
  },
  !(_id match "__i18n_") => {
    "translations": {
      "de": {
        isHome,
        url
      },
      "fr":__translation_refs[_key == "fr"][0]->{
        isHome,
        url
      },
      "it":__translation_refs[_key == "it"][0]->{
        isHome,
        url
      },
    }
  },
  (_id match "*__i18n_*") => {
    "translations":
    *[references(^._id) && __i18n_lang =="de"]{
      "de":{
        isHome,
        url,
        __i18n_lang,
      },
      "fr":(__translation_refs[]->{
        isHome,
        url,
        __i18n_lang,
      })[__i18n_lang=="fr"][0], 
      "it":(__translation_refs[]->{
        isHome,
        url,
        __i18n_lang,
      })[__i18n_lang=="it"][0], 
    }[0]
  }
}`;
}
